<template>
    <div v-if="album" class="h-screen-header rounded-xl shadow-xl">
        <Hero :image="album.image">
            <div class="flex flex-col justify-between h-32 md:h-56">
                <div class="ml-4 flex flex-col justify-start items-center md:items-start z-10">
                    <div class="flex items-center ml-1">
                        <div class="text-4xl font-medium">{{ album.name }}</div>
                        <button class="btn btn-active btn-sm ml-2" @click.stop="addFavouriteAlbum">
                            <svg xmlns="http://www.w3.org/2000/svg" :class="['h-6', 'w-6', isFavourite ? 'fill-current' : '']" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                        </button>
                    </div>
                    <div class="flex flex-col items-center md:items-start my-2 text-gray-600 !hidden md:!block">
                        <span>专辑 由 {{ album.artist}} · {{ album.year }} 发行 </span>
                        <span>{{album.tracks.length}} 首歌曲, {{formatDuration(album.duration)}} 时长 添加于{{formatDate(album.created)}} · {{album.genreId}}</span>
                    </div>
                    <div class="flex flex-col items-center md:items-start my-1 text-gray-600 text-ellipsis overflow-hidden h-12 !hidden md:!block" v-if="album.comment">
                        <span>{{ album.comment}}</span>
                    </div>
                    <div>
                        <div class="badge badge-neutral">
                            <router-link :to="{ name: 'artist', params: { id: album.artistId } }">
                                {{ album.artist }}
                            </router-link>
                        </div>
                        <div class="badge badge-neutral" v-if="album.year">
                            {{ album.year }}
                        </div>
                        <div class="badge badge-neutral" v-if="album.genreId">
                            <router-link :to="{ name: 'genre', params: { id: album.genreId } }">
                                {{ album.genreId }}
                            </router-link>
                        </div>
                        <div class="badge badge-neutral mr-1" v-for="(item, index) in album.otherArtist" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <div class="ml-4 z-10 flex flex-row justify-center md:justify-start">
                    <button class="btn btn-neutral btn-active btn-sm" @click="playNow(album.id)">
                        PLAY
                    </button>
                    <div class="drawer drawer-end w-16 ml-2">
                        <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
                        <div class="drawer-content">
                            <label for="my-drawer-4" class="btn btn-neutral btn-active btn-sm" @click="handleEditAlbum">EDIT</label>
                        </div>
                        <div class="drawer-side">
                            <label for="my-drawer-4" aria-label="close sidebar" class="drawer-overlay"></label>
                            <div class="p-4 w-80 min-h-full bg-base-200 text-base-content">
                                <div class="font-medium text-xl mb-4">编辑专辑信息</div>

                                <div class="mb-1">专辑:</div>
                                <div>
                                    <input type="text"
                                        v-model="FormData.name"
                                        class="input input-bordered input-sm w-full max-w-xs" />
                                </div>
                                <div class="mb-1 mt-1">专辑封面:</div>
                                <div>
                                    <bk-upload
                                        :files="files1"
                                        :theme="'picture'"
                                        :multiple="false"
                                        :with-credentials="true"
                                        :header="uploadHeader"
                                        :handle-res-code="handleRes"
                                        :size="{ maxFileSize: 5, maxImgSize: 20 }"
                                        :url="uploadUrl"
                                        name="upload_file"
                                    ></bk-upload>
                                </div>
                                <div class="mb-1 mt-1">描述:</div>
                                <textarea class="textarea textarea-bordered textarea-xs w-full max-w-xs h-48" placeholder="." v-model="FormData.comment"></textarea>
                                <button class="btn btn-neutral btn-sm btn-wide" @click="handleSave">保存</button>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-neutral btn-active btn-sm" @click="handleDelete(album.id)">
                        DELETE
                    </button>
                </div>
            </div>
        </Hero>
        <div class="mt-2 overflow-y-auto h-[40%] md:h-[58%]">
            <TrackList :tracks="album.tracks" no-album />
        </div>
    </div>
</template>
<script>
    import Hero from '../../../components/library/Hero'
    import TrackList from '@/views/library/track/TrackList'
    export default {
        components: {
            Hero, TrackList
        },
        data() {
            return {
                album: null,
                id: this.$route.params.id,
                isFavourite: false,
                FormData: {},
                artistsData: [],
                uploadHeader: [
                    {name: 'X-CSRFToken', value: this.getCookie('django_vue_cli_csrftoken')},
                    {name: 'AUTHORIZATION', value: this.getCookie('AUTHORIZATION')}
                ],
                uploadUrl: '/api/upload_image/',
                files1: []
            }
        },
        async created() {
            this.album = await this.$api.subsonic.getAlbumDetails(this.id)
            this.isFavourite = this.album.favourite
        },
        methods: {
            async playNow(id) {
                const album = await this.$api.subsonic.getAlbumDetails(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: album.tracks
                })
            },
            shuffleNow() {
                return this.$store.dispatch('player/shuffleNow', {
                    tracks: this.album && this.album.tracks ? this.album.tracks : []
                })
            },
            setNextInQueue() {
                if (this.album) {
                    return this.$store.dispatch('player/setNextInQueue', this.album.tracks)
                }
            },
            addToQueue() {
                if (this.album) {
                    return this.$store.dispatch('player/addToQueue', this.album.tracks)
                }
            },
            toggleFavourite() {
                return this.favouriteStore.toggle('album', this.id)
            },
            formatDuration(value) {
                if (!isFinite(value)) {
                    return '∞'
                }
                const minutes = Math.floor(value / 60)
                const seconds = Math.floor(value % 60)
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
            },
            addFavouriteAlbum() {
                if (this.isFavourite) {
                    this.$api.subsonic.removeFavourite(this.album.id, 'album').then((res) => {
                        this.isFavourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(this.album.id, 'album').then((res) => {
                        this.isFavourite = true
                    })
                }
            },
            handleSave() {
                this.$api.Task.postAlbum({
                    id: this.id,
                    album_img: this.FormData.album_img,
                    comment: this.FormData.comment,
                    name: this.FormData.name
                }).then((res) => {
                    if (res.result) {
                        this.$cwMessage('修改成功', 'success')
                        this.$api.subsonic.getAlbumDetails(this.id).then((res) => {
                            this.album = res
                        })
                    } else {
                        this.$cwMessage('修改失败', 'error')
                    }
                })
            },
            handleDelete() {
                this.$api.Task.deleteAlbum({ 'id': this.id }).then((res) => {
                    if (res.result) {
                        this.$cwMessage('删除成功', 'success')
                        this.$router.push({path: '/albums/recently-added/'})
                    } else {
                        this.$cwMessage('删除失败', 'error')
                    }
                })
            },
            handleEditAlbum() {
                if (this.album.image) {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: this.album.image
                        }
                    ]
                }
                this.FormData = {
                    name: this.album.name,
                    comment: this.album.comment
                }
            },
            // 渲染图片
            handleRes(response) {
                if (response.result) {
                    this.FormData.album_img = response.data
                    return true
                } else {
                    return false
                }
            },
            handleCopy(k, v) {
                if (k === 'album_img') {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: v
                        }
                    ]
                    this.FormData.album_img = v
                } else {
                    this.FormData[k] = v
                }
            }
        }
    }
</script>
