<template>
    <div>
        <div class="w-full" v-if="track && isOpen">
            <ProgressBar style="margin-bottom: -5px; margin-top: -6px;" />
            <div class="flex">
                <!-- Track info --->
                <div class="w-4/12">
                    <div class="flex items-center">
                        <router-link :to="{ name: 'playingList', params: { id: track.id } }" class="mx-2 h-16 w-16 my-1 hover:brightness-50">
                            <img v-if="track.image" :src="track.image"
                                style="width: 56px; height: 56px; object-fit: cover;">
                            <img v-else src="/static/dist/img/album_null.jpg" style="width: 56px; height: 56px; object-fit: cover;">
                        </router-link>
                        <div class="min-w-0 flex-1">
                            <router-link :to="{ name: 'album', params: { id: track.albumId } }" class="hover:underline">
                                <div class="truncate">
                                    {{ streamTitle || track.title }}
                                </div>
                            </router-link>
                            <router-link :to="{ name: 'artist', params: { id: track.artistId } }" class="hover:underline">
                                <div class="truncate text-gray-400 text-xs">
                                    {{ track.artist || track.album }}
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- Controls--->
                <div class="flex flex-1 items-center justify-center">
                    <button variant="link" class="m-2 d-none d-sm-inline-block" @click="previous">
                        <svg t="1697292326917" class="icon fill-current hover:fill-gray-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4372" width="32" height="32"><path d="M810.666667 331.349333c0-66.304-72.362667-107.306667-129.237334-73.173333l-301.056 180.650667c-55.253333 33.152-55.253333 113.194667 0 146.346666l301.056 180.650667c56.874667 34.133333 129.237333-6.826667 129.237334-73.173333V331.349333zM424.277333 512L725.333333 331.349333v361.301334L424.277333 512zM256 256a42.666667 42.666667 0 0 1 42.666667 42.666667v426.666666a42.666667 42.666667 0 1 1-85.333334 0V298.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z" p-id="4373"></path></svg>
                    </button>
                    <label class="swap swap-flip">
                        <input type="checkbox" v-model="isPlaying" />
                        <svg t="1697292202086" class="swap-off fill-current w-8 h-8 hover:fill-gray-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                        <svg t="1697292822786" class="swap-on fill-current w-8 h-8 hover:fill-gray-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4492" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM320 320v384h128V320H320z m256 0v384h128V320H576z" p-id="4493"></path></svg>
                    </label>
                    <button variant="link" class="m-2" @click="next">
                        <svg t="1697292423300" class="icon fill-current hover:fill-gray-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2433" width="32" height="32"><path d="M213.333333 331.349333c0-66.304 72.362667-107.306667 129.237334-73.173333l301.056 180.650667c55.253333 33.152 55.253333 113.194667 0 146.346666L342.613333 765.824c-56.874667 34.133333-129.237333-6.826667-129.237333-73.173333V331.349333zM599.722667 512L298.666667 331.349333v361.301334L599.722667 512zM768 256a42.666667 42.666667 0 0 1 42.666667 42.666667v426.666666a42.666667 42.666667 0 1 1-85.333334 0V298.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z" p-id="2434"></path></svg>
                    </button>
                </div>

                <!-- Controls right --->
                <!-- 音量 --->
                <div class="w-4/12 flex items-center justify-end pr-3 !hidden md:!flex">
                    <div class="dropdown dropdown-top m-2">
                        <label tabindex="0">
                            <svg class="swap-on fill-current w-6 h-6 cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M14,3.23V5.29C16.89,6.15 19,8.83 19,12C19,15.17 16.89,17.84 14,18.7V20.77C18,19.86 21,16.28 21,12C21,7.72 18,4.14 14,3.23M16.5,12C16.5,10.23 15.5,8.71 14,7.97V16C15.5,15.29 16.5,13.76 16.5,12M3,9V15H7L12,20V4L7,9H3Z" /></svg>
                        </label>
                        <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box">
                            <vue-slider class="pt-2" style="height: 120px;" direction="btt"
                                :min="0" :max="100" :step="1"
                                :value="volume" @change="setVolume"
                            ></vue-slider>
                        </ul>
                    </div>
                    <!-- 收藏 --->
                    <button variant="link" class="m-2 d-none d-sm-inline-block" @click="addFavouriteTrack(track)">
                        <svg xmlns="http://www.w3.org/2000/svg" :class="['h-5', 'w-5', track.favourite ? 'fill-current' : '']" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    </button>
                    <!-- 循环播放 --->
                    <button variant="link" size="lg" class="m-2" @click="toggleRepeat">
                        <svg t="1697293698886" :class="['h-5', 'w-5', repeat ? 'fill-purple-500' : '']" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5115" width="32" height="32"><path d="M512 170.666667V42.666667L341.333333 213.333333l170.666667 170.666667V256c140.8 0 256 115.2 256 256 0 42.666667-12.8 85.333333-29.866667 119.466667l64 64C832 640 853.333333 580.266667 853.333333 512c0-187.733333-153.6-341.333333-341.333333-341.333333z m0 597.333333c-140.8 0-256-115.2-256-256 0-42.666667 12.8-85.333333 29.866667-119.466667L221.866667 328.533333C192 384 170.666667 443.733333 170.666667 512c0 187.733333 153.6 341.333333 341.333333 341.333333v128l170.666667-170.666666-170.666667-170.666667v128z" p-id="5116"></path></svg>
                    </button>
                    <!-- 随机播放 --->
                    <button variant="link" class="m-2" @click="toggleShuffle">
                        <svg t="1697293756816" :class="['h-5', 'w-5', shuffle ? 'fill-purple-500' : '']" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2318" width="32" height="32"><path d="M68.253 349.325h162.675c8.156 0 32.477 8.156 40.633 16.311l56.944 56.944c16.311 24.467 48.788 24.467 73.109 0 24.467-16.311 24.467-48.788 0-73.109l-56.944-56.944c-24.321-24.321-73.109-48.788-113.887-48.788H68.254c-32.477 0-48.788 24.467-48.788 48.788 0 32.331 24.467 56.798 48.788 56.798z m609.923 0h89.42v65.099c0 16.311 8.156 24.467 24.467 16.311l186.996-121.897c16.311-8.156 16.311-24.467 0-32.477L792.063 154.464c-16.311-8.156-24.467 0-24.467 16.311v89.275h-89.42c-81.265 0-97.575 40.633-154.519 97.575L336.661 625.594c-32.477 32.477-130.052 65.099-178.841 65.099H68.4c-32.477 0-48.788 24.321-48.788 48.788 0 32.477 24.321 48.788 48.788 48.788h89.42c81.265 0 195.152-40.633 252.095-97.575l186.996-268.26c40.633-40.633 32.477-73.109 81.265-73.109z m300.883 373.992L792.063 593.265c-16.311-8.156-24.467 0-24.467 16.311v81.265h-89.42c-8.156 0-32.477-8.156-40.633-16.311l-56.944-56.944c-16.311-24.467-48.788-24.467-73.109 0-24.321 16.311-24.321 48.788 0 73.109l56.944 56.944c24.467 24.321 73.109 48.788 113.887 48.788h89.42v65.099c0 16.311 8.156 24.321 24.467 16.311L979.204 755.94c16.02-8.156 16.02-24.467-0.146-32.622z" p-id="2319"></path></svg>
                    </button>
                    <button variant="link" class="m-2" @click="close">
                        <svg t="1700025561666" class="icon fill-current w-5 h-5" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3629" width="32" height="32"><path d="M512 981.333c-258.133 0-469.333-211.2-469.333-469.333S253.867 42.667 512 42.667 981.333 253.867 981.333 512 770.133 981.333 512 981.333zM512 128c-211.2 0-384 172.8-384 384s172.8 384 384 384 384-172.8 384-384-172.8-384-384-384z"></path><path d="M332.8 691.2c8.533 8.533 19.2 12.8 29.867 12.8s21.333-4.267 29.866-12.8L512 571.733 631.467 691.2c8.533 8.533 19.2 12.8 29.866 12.8s21.334-4.267 29.867-12.8c17.067-17.067 17.067-42.667 0-59.733L571.733 512 691.2 392.533c17.067-17.066 17.067-42.666 0-59.733s-42.667-17.067-59.733 0L512 452.267 392.533 332.8c-17.066-17.067-42.666-17.067-59.733 0s-17.067 42.667 0 59.733L452.267 512 332.8 631.467c-17.067 17.066-17.067 42.666 0 59.733"></path></svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- 小播放 --->
        <div class="w-full relative" v-if="track && !isOpen">
            <div class="absolute -left-2/4 -bottom-4 bg-base-200 rounded-xl shadow-xl w-56">
                <ProgressBar style="margin-bottom: -5px; margin-top: -6px;" />
                <div class="flex flex-col">
                    <!-- Track info --->
                    <div>
                        <div class="flex items-center justify-center">
                            <router-link :to="{ name: 'playingList', params: { id: track.id } }" class="mx-2 h-16 w-16 mt-2 hover:brightness-50">
                                <img v-if="track.image" :src="track.image" style="width: 56px; height: 56px; object-fit: cover;">
                                <img v-else src="/static/dist/img/album_null.jpg" style="width: 56px; height: 56px; object-fit: cover;">
                            </router-link>
                            <div class="min-w-0 flex-1">
                                <router-link :to="{ name: 'album', params: { id: track.albumId } }" class="hover:underline">
                                    <div class="truncate">
                                        {{ streamTitle || track.title }}
                                    </div>
                                </router-link>
                                <router-link :to="{ name: 'artist', params: { id: track.artistId } }" class="hover:underline">
                                    <div class="truncate text-gray-400 text-xs">
                                        {{ track.artist || track.album }}
                                    </div>
                                </router-link>
                            </div>
                            <button variant="link" class="m-2" @click="open">
                                <svg t="1700213453662" class="icon fill-current w-5 h-5" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2411" width="64" height="64"><path d="M448 672c-6.4 0-19.2 0-25.6-6.4-12.8-12.8-12.8-32 0-44.8L531.2 512 422.4 409.6c-12.8-12.8-12.8-32 0-44.8s32-12.8 44.8 0l128 128c12.8 12.8 12.8 32 0 44.8l-128 128C467.2 672 454.4 672 448 672z"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ProgressBar from '@/views/library/player/ProgressBar'
    import VueSlider from 'vue-slider-component'
    import {mapGetters} from 'vuex'

    export default {
        components: {
            ProgressBar, VueSlider
        },
        data() {
            return {
                isOpen: localStorage.getItem('isOpenBar') !== 'false'
            }
        },
        computed: {
            ...mapGetters(['track', 'trackId', 'shuffle', 'repeat']),
            visible() {
                // return this.$store.state.player.queue.length > 0
                return true
            },
            isPlaying: {
                get() {
                    return this.$store.state.common.isPlaying
                },
                set() {
                    this.$store.dispatch('playPause')
                }
            },
            volume() {
                return 100
                // return this.$store.state.player.volume
            },
            isMuted() {
                return true
                // return this.$store.state.player.volume <= 0.0
            },
            repeatActive() {
                // return this.$store.state.player.repeat
                return false
            },
            shuffleActive() {
                // return this.$store.state.player.shuffle
                return false
            },
            isFavourite() {
                return ''
            },
            streamTitle() {
                return this.$store.state.streamTitle
            },
            documentTitle() {
                const trackTitle = this.track && this.track.title ? this.track.title : ''
                const trackArtist = this.track && this.track.artist ? this.track.artist : ''
                const trackAlbum = this.track && this.track.album ? this.track.album : ''
                return [
                    this.streamTitle || trackTitle,
                    trackArtist || trackAlbum,
                    'Music Tag Web'
                ].filter(x => !!x).join(' • ')
            }
        },
        watch: {
            documentTitle: {
                immediate: true,
                handler(value) {
                    document.title = value
                }
            },
            // trackId() {
            //     this.$api.subsonic.scrobble(this.trackId)
            // }
        },
        destroyed() {
            document.title = 'Music Tag Web'
        },
        methods: {
            playPause() {
                return this.$store.dispatch('playPause')
            },
            next() {
                return this.$store.dispatch('next')
            },
            previous() {
                return this.$store.dispatch('previous')
            },
            setVolume(volume) {
                return this.$store.dispatch('setVolume', parseFloat(volume / 100))
            },
            toggleRepeat() {
                return this.$store.dispatch('toggleRepeat')
            },
            toggleShuffle() {
                return this.$store.dispatch('toggleShuffle')
            },
            close() {
                localStorage.setItem('isOpenBar', 'false')
                this.isOpen = false
            },
            open() {
                localStorage.setItem('isOpenBar', 'true')
                this.isOpen = true
            },
            handlePlayingDetails() {
                this.$router.push({name: 'playing', params: {id: this.track.id}})
            },
            addFavouriteTrack(item) {
                console.log(1313, item)
                if (item.favourite) {
                    this.$api.subsonic.removeFavourite(item.id, 'track').then((res) => {
                        item.favourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(item.id, 'track').then((res) => {
                        item.favourite = true
                    })
                }
            }
        }
    }
</script>
<style scoped>
.player {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    max-height: 0;
    transition: max-height 0.5s;
}

.visible {
    height: auto;
    max-height: 100px;
}

.icon {
    display: flex;
    align-items: center;
}
/deep/ .vue-slider-process {
    background-color: #5c1fdd;
}
</style>
