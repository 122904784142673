<template>
    <div>
        <ul class="menu bg-base-100 w-56 my-2 rounded-box h-screen-header shadow-xl">
            <SidebarNav/>
        </ul>
    </div>
</template>
<script>
    import SidebarNav from '@/components/library/SidebarNav'

    export default {
        components: {
            SidebarNav,
        },
        setup() {
            return {
                store: '',
            }
        },
    }
</script>
<style>
</style>
