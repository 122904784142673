<template>
    <vue-slider
        v-bind="$attrs"
        :value="progress"
        :min="0"
        :max="1"
        :interval="0.00001"
        :lazy="true"
        :contained="true"
        :step-active-style="{ 'background-color': '#fff' }"
        :dot-options="{ tooltip: 'active' }"
        :tooltip-formatter="formatter"
        @change="seek"
        class="cursor-pointer"
    >
        <template v-slot:dot>
            <div></div>
        </template>
    </vue-slider>
</template>
<script>
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'

    const {mapGetters} = require('vuex')

    export default {
        components: {
            VueSlider
        },
        computed: {
            ...mapGetters(['progress'])
        },
        methods: {
            formatDuration(value) {
                if (!isFinite(value)) {
                    return '∞'
                }
                const minutes = Math.floor(value / 60)
                const seconds = Math.floor(value % 60)
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
            },
            formatter(value) {
                const duration = this.$store.state.common.duration
                const time = value * duration
                return `${this.formatDuration(time)} / ${this.formatDuration(duration)}`
            },
            seek(value) {
                this.$store.dispatch('seek', value)
            }
        }
    }
</script>
<style scoped>
/deep/ .vue-slider-rail {
    background-color: hsl(var(--b5) / var(--tw-bg-opacity));
}
/deep/ .vue-slider-process {
    background-color: #5c1fdd;
}
</style>
