<template>
    <div id="container">
        <router-view :key="$route.path"></router-view>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
</style>
