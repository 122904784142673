<template>
    <div class="overflow-y-auto h-screen-header">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <div class="flex justify-between w-full">
                <div>
                    <a v-for="{ value, text } in options" :key="value"
                        :class="{ 'tab': true, 'tab-active': sortValue === value }">
                        <router-link :to="{ 'path': '/artists/' + value }">
                            {{ text }}
                        </router-link>
                    </a>
                </div>
                <div class="flex justify-center items-center">
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'grid' }" @click="showMode = 'grid'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M170.666667 85.333333a85.333333 85.333333 0 0 0-85.333334 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333333-85.333334H170.666667zM170.666667 554.666667a85.333333 85.333333 0 0 0-85.333334 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333334 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333333-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333333-85.333333H170.666667zM640 85.333333a85.333333 85.333333 0 0 0-85.333333 85.333334v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333333h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333333V170.666667a85.333333 85.333333 0 0 0-85.333334-85.333334h-213.333333zM640 554.666667a85.333333 85.333333 0 0 0-85.333333 85.333333v213.333333a85.333333 85.333333 0 0 0 85.333333 85.333334h213.333333a85.333333 85.333333 0 0 0 85.333334-85.333334v-213.333333a85.333333 85.333333 0 0 0-85.333334-85.333333h-213.333333z"></path></svg>
                    </a>
                    <a :class="{ 'tab': true, 'tab-active': showMode === 'list' }" @click="showMode = 'list'">
                        <svg class="icon w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M134.976 853.312H89.6c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32 0 27.328-20.288 48.256-46.912 48.256zM134.976 560.32H89.6C63.04 560.32 42.688 539.392 42.688 512s20.352-48.32 46.912-48.32h45.376c26.624 0 46.912 20.928 46.912 48.32s-20.288 48.32-46.912 48.32zM134.976 267.264H89.6c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h45.376c26.624 0 46.912 20.928 46.912 48.256 0 27.392-20.288 48.32-46.912 48.32zM311.744 853.312c-26.56 0-46.912-20.928-46.912-48.256 0-27.392 20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32 0 27.328-20.288 48.256-46.912 48.256H311.744c1.6 0 1.6 0 0 0zM311.744 560.32c-26.56 0-46.912-20.928-46.912-48.32s20.352-48.32 46.912-48.32h622.72c26.56 0 46.848 20.928 46.848 48.32s-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0zM311.744 267.264c-26.56 0-46.912-20.928-46.912-48.32 0-27.328 20.352-48.256 46.912-48.256h622.72c26.56 0 46.848 20.928 46.848 48.256 0 27.392-20.288 48.32-46.912 48.32H311.744c1.6 0 1.6 0 0 0z"></path></svg>
                    </a>
                </div>
            </div>
        </div>
        <ContentLoader v-slot :loading="loading">
            <ArtistList :items="items" v-if="showMode === 'grid'" />
            <ArtistItems :items="items" v-else />
        </ContentLoader>
    </div>
</template>
<script>
    import ArtistList from './ArtistList.vue'
    import ArtistItems from './ArtistItems.vue'
    import ContentLoader from '@/components/library/ContentLoader'

    export default {
        components: {
            ArtistList, ContentLoader, ArtistItems
        },
        props: {
            sort: {type: String, default: null}
        },
        data() {
            return {
                loading: true,
                options: [
                    {text: '最多专辑', value: 'most-albums'},
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '缺失封面', value: 'less_cover'}
                ],
                items: [],
                showMode: 'grid'
            }
        },
        computed: {
            sortedItems() {
                return this.items
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                async handler() {
                    this.items = Object.freeze(await this.$api.subsonic.getArtists(this.sortValue))
                }
            }
        },
        async created() {
            this.items = Object.freeze(await this.$api.subsonic.getArtists(this.sortValue))
            this.loading = false
        }
    }
</script>
