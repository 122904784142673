<template>
    <div>
        <!--      头部菜单      -->
        <top-header></top-header>
        <!--      内容区域      -->
        <container></container>
    </div>
</template>

<script>
    import topHeader from './header.vue'
    import container from './container.vue'

    export default {
        components: {
            topHeader,
            container
        },
        data() {
            return {
                title: '音乐标签Web版',
                headerTitle: '',
                imgSrc: '',
            }
        },
        computed: {
        },
        created() {
            // this.initLogo()
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style scoped>
.monitor-logo {
    width: 32px;
    height: 32px;
}

.monitor-logo-icon {
    width: 32px;
    height: 32px;
}
</style>
