<template>
    <ContentLoader v-slot :loading="playlist == null" class="h-screen-header">
        <Hero :image="playlist.image">
            <div class="flex flex-col justify-between h-56">
                <div class="ml-4 flex flex-col justify-start items-start z-10">
                    <div class="flex items-center ml-1">
                        <div class="text-4xl font-medium text-clip overflow-hidden h-10">{{ playlist.name }}</div>
                    </div>
                    <div class="flex flex-col my-2 text-gray-600">
                        <span>播放列表 {{ playlist.tracks.length }} 首歌曲</span>
                        <span>由{{playlist.owner}} 创建于{{formatDate(playlist.createdAt)}}</span>
                    </div>
                    <div v-if="playlist.comment" class="mb-3 text-gray-600 text-ellipsis overflow-hidden h-12">
                        {{ playlist.comment }}
                    </div>
                </div>
                <div class="ml-4 z-10 flex">
                    <button class="btn btn-neutral btn-active btn-sm" @click="playNow(playlist.id)">
                        Play
                    </button>
                    <div class="drawer drawer-end w-16 ml-2">
                        <input id="my-drawer-4" type="checkbox" class="drawer-toggle" />
                        <div class="drawer-content">
                            <!-- Page content here -->
                            <label for="my-drawer-4" class="btn btn-neutral btn-active btn-sm" @click="handleEdit">EDIT</label>
                        </div>
                        <div class="drawer-side">
                            <label for="my-drawer-4" aria-label="close sidebar" class="drawer-overlay"></label>
                            <div class="p-4 w-80 min-h-full bg-base-200 text-base-content">
                                <div class="font-medium text-xl mb-4">编辑播放列表信息</div>

                                <div class="mb-1">名称:</div>
                                <div>
                                    <input type="text"
                                        v-model="FormData.name"
                                        class="input input-bordered input-sm w-full max-w-xs" />
                                </div>
                                <div class="mb-1 mt-1">封面:</div>
                                <div>
                                    <bk-upload
                                        :files="files1"
                                        :theme="'picture'"
                                        :multiple="false"
                                        :with-credentials="true"
                                        :header="uploadHeader"
                                        :handle-res-code="handleRes"
                                        :size="{ maxFileSize: 5, maxImgSize: 20 }"
                                        :url="uploadUrl"
                                        name="upload_file"
                                    ></bk-upload>
                                </div>
                                <div class="mb-1 mt-1">描述:</div>
                                <textarea class="textarea textarea-bordered textarea-xs w-full max-w-xs h-48" placeholder="." v-model="FormData.description"></textarea>
                                <button class="btn btn-neutral btn-sm btn-wide" @click="handleSave">保存</button>
                                <div class="mt-4">封面推荐:</div>
                                <div v-for="(item2, index) in uniqueTrackList" :key="index">
                                    <div class="flex justify-center items-center mt-1 mb-2">
                                        <bk-image fit="contain" :src="item2.image"
                                            class="mask mask-squircle w-16 h-16 cursor-pointer"
                                            @click="handleCopy('playlist_img', item2.image)">
                                        </bk-image>
                                        <div class="flex-1 ml-2">
                                            <div class="cursor-pointer hover:underline"
                                                @click="handleCopy('name', item2.artist)">{{item2.artist}}</div>
                                            <div class="truncate w-56 text-gray-400 cursor-pointer hover:underline"
                                                @click="handleCopy('description', item2.album)">{{item2.album}}</div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-neutral btn-active btn-sm" @click="deletePlaylist">
                        DELETE
                    </button>
                </div>
            </div>
        </Hero>
        <TrackList v-if="playlist.tracks.length > 0" :tracks="playlist.tracks" :index-field="'index'"
            class="overflow-y-auto h-[58%]">
        </TrackList>
    </ContentLoader>
</template>
<script>
    import TrackList from '@/views/library/track/TrackList'
    import Hero from '../../../components/library/Hero'
    import ContentLoader from '@/components/library/ContentLoader'

    const {getCoverArtUrl} = require('../../../api/apiUrl/subsonic/serializers')
    export default {
        components: {
            TrackList, Hero, ContentLoader
        },
        props: {
        },
        data() {
            return {
                playlist: null,
                showEditModal: false,
                id: this.$route.params.id,
                uploadHeader: [
                    {name: 'X-CSRFToken', value: this.getCookie('django_vue_cli_csrftoken')},
                    {name: 'AUTHORIZATION', value: this.getCookie('AUTHORIZATION')}
                ],
                files1: [],
                uploadUrl: '/api/upload_image/',
                FormData: {},
                artistsData: [],
                uniqueTrackList: []
            }
        },
        async created() {
            this.playlist = await this.$api.subsonic.getPlaylist(this.id)
            this.FormData = {
                name: this.playlist.name,
                description: this.playlist.comment,
                playlist_img: this.playlist.image ? this.playlist.image : ''
            }
            this.uniqueTrackList = this.playlist.tracks.reduce((unique, item) => {
                // 判断当前元素的 id 是否已经存在于 unique 列表中
                if (!unique.some(elem => elem.album === item.album)) {
                    unique.push(item)
                }
                return unique
            }, [])
            this.files1 = [
                {
                    name: 'cover.png',
                    status: 'done',
                    url: this.playlist.image
                }
            ]
        },
        methods: {
            async playNow(id) {
                const playlist = await this.$api.subsonic.getPlaylist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: playlist.tracks
                })
            },
            removeTrack(index) {
                this.playlist.tracks.splice(index, 1)
                return this.playlistStore.removeTrack(this.id, index)
            },
            updatePlaylist(value) {
                this.playlist = value
                return this.playlistStore.update(this.playlist)
            },
            deletePlaylist() {
                this.$api.subsonic.deletePlaylist(this.id).then((res) => {
                    this.$cwMessage('删除成功', 'success')
                    this.$router.push({path: '/playlists/recently-added/'})
                })
            },
            handleEdit() {
                this.showEditModal = true
                this.genrePlaylistCoverFuc()
            },
            handleRes(response) {
                if (response.result) {
                    this.FormData.playlist_img = response.data
                    return true
                } else {
                    return false
                }
            },
            handleCopy(k, v) {
                if (k === 'playlist_img') {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: v
                        }
                    ]
                    this.FormData.playlist_img = v
                } else {
                    this.FormData[k] += ',' + v
                }
            },
            handleSave() {
                this.$api.Task.putPlayList({id: this.id, ...this.FormData}).then((res) => {
                    if (res.result) {
                        this.$cwMessage('修改成功', 'success')
                        this.$api.subsonic.getPlaylist(this.id).then((res) => {
                            this.playlist = res
                        })
                    }
                })
            },
            genrePlaylistCoverFuc() {
                this.$api.Task.genrePlaylistCover({'id': this.id}).then((res) => {
                    if (res.result) {
                        const img = getCoverArtUrl({'coverArt': res.data.coverArt})
                        if (this.uniqueTrackList && this.uniqueTrackList[0].coverArt === this.id) {
                            this.uniqueTrackList[0] = {
                                'image': img,
                                'artist': res.data.artist,
                                'album': res.data.album,
                                'coverArt': this.id
                            }
                        } else {
                            this.uniqueTrackList.unshift({
                                'image': img,
                                'artist': res.data.artist,
                                'album': res.data.album,
                                'coverArt': this.id
                            })
                        }
                    }
                })
            }
        }
    }
</script>
