// import {reUrl} from '../../axiosconfig/axiosconfig'
function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

// todo test
export const auth = {
    // server: 'http://127.0.0.1:88',
    server: '',
    username: getCookie('username'),
    salt: getCookie('salt'),
    hash: getCookie('hash')

}
