<template>
    <Tiles>
        <Tile v-for="item in items" :key="item.id"
            :to="{ name: 'artist', params: { id: item.id } }"
            :title="item.name" :image="item.image">
            <template #text>
                <div class="text-sm">
                    <strong>{{ item.albumCount }}</strong> 专辑
                </div>
            </template>
            <template #play>
                <div class="absolute top-[43%] z-10 left-[39%] play-icon cursor-pointer" @click="playNow(item.id)">
                    <svg class="fill-primary w-12 h-12 hover:fill-accent" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                </div>
            </template>
        </Tile>
    </Tiles>
</template>
<script>
    import Tiles from '@/components/library/Tiles'
    import Tile from '@/components/library/Tile'
    export default {
        components: {
            Tiles, Tile
        },
        props: {
            items: { type: Array, required: true }
        },
        created() {
            console.log(this.items)
        },
        methods: {
            async playNow(id) {
                const tracks = await this.$api.subsonic.getTracksByArtist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: tracks
                })
            }
        }
    }
</script>
