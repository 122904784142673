<template>
    <div class="flex flex-col md:flex-row items-center md:items-start position-relative mb-3 rounded-xl p-4">
        <div class="backdrop"
            :style="{ 'background-image': 'linear-gradient(to bottom, transparent, black),'+ backgroundImage }">
        </div>
        <img v-if="image" :src="image" class="rounded-xl">
        <img v-else src="/static/dist/img/album_null.jpg" class="rounded-xl">
        <div class="body pt-4 pt-md-0 pl-md-4">
            <slot/>
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            image: {type: String, default: null},
        },
        computed: {
            backgroundImage() {
                return `url('${this.image || '/static/dist/img/album_null.jpg'}')`
            }
        },
    }
</script>
<style scoped>
img {
    display: block;
    width: 250px;
    height: auto;
    max-width: 75%;
    aspect-ratio: 1;
    object-fit: cover;
    z-index: 2;
}

.backdrop {
    position: absolute;
    z-index: 1;
    width: 81%;
    top: 3%;
    height: calc(250px);
    transform: scale(1.025);
    filter: blur(8px);
    opacity: 0.25;
    background-size: max(100%, 1000px) auto;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
