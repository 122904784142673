<template>
    <div class="rounded-xl">
        <table class="table">
            <!-- head -->
            <thead>
                <tr>
                    <th>#</th>
                    <th>标题</th>
                    <th>艺术家</th>
                    <th class="!hidden md:!table-cell">时长</th>
                    <th class="!hidden md:!table-cell">大小</th>
                    <th class="!hidden md:!table-cell">比特率</th>
                    <th class="!hidden md:!table-cell">播放</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in tracks" :key="index" @click="play(index)" :draggable="true"
                    :class="['hover:bg-base-300', 'cursor-pointer', item.id === trackId && isPlaying ? 'bg-base-300 text-purple-600' : '']">
                    <th>
                        <div v-if="item.id === trackId && isPlaying">
                            <span class="loading loading-bars loading-xs"></span>
                        </div>
                        <div v-else>
                            <div v-if="indexField === 'index'">
                                {{ index + 1 }}
                            </div>
                            <div v-else>
                                {{ item.track }}
                            </div>
                        </div>
                    </th>
                    <td>
                        <div class="flex items-center">
                            <div class="truncate text-ellipsis w-24 md:w-48">{{ item.title }}</div>
                            <div class="badge ml-1" v-if="item.fromFolder === 'webdav'">
                                <svg t="1705398013860" class="icon fill-current w-4 h-4" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2454" width="200" height="200"><path d="M533.333333 256a212.416 212.416 0 0 1 212.864 205.333333l0.277334 7.488a52.970667 52.970667 0 0 0 35.413333 48.064l3.136 1.088a131.584 131.584 0 0 1 89.024 109.418667A128.128 128.128 0 0 1 746.666667 768H303.552a152.554667 152.554667 0 0 1-153.301333-132.629333 148.821333 148.821333 0 0 1 123.306666-163.690667l7.296-1.237333a53.034667 53.034667 0 0 0 42.88-40.682667l1.6-7.210667A214.570667 214.570667 0 0 1 533.333333 256m0-64a277.333333 277.333333 0 0 0-270.442666 216.554667A213.226667 213.226667 0 0 0 298.666667 832h448a192 192 0 0 0 63.488-373.034667A277.12 277.12 0 0 0 533.333333 192z" p-id="2455"></path><path d="M405.333333 618.666667m32 0l192 0q32 0 32 32l0 0q0 32-32 32l-192 0q-32 0-32-32l0 0q0-32 32-32Z" fill="#FF9C00" p-id="2456"></path></svg>
                            </div>
                        </div>
                    </td>
                    <td>{{ item.artist }}</td>
                    <td class="!hidden md:!table-cell">{{ formatDuration(item.duration) }}</td>
                    <td class="!hidden md:!table-cell">{{ formatSize(item.size) }}</td>
                    <td class="!hidden md:!table-cell">{{ item.bitrate }}kps</td>
                    <td class="!hidden md:!table-cell">{{ item.playsCount }}</td>
                    <td class="!hidden md:!table-cell">
                        <button class="btn btn-active btn-sm ml-2" @click.stop="addFavouriteTrack(item)">
                            <svg xmlns="http://www.w3.org/2000/svg" :class="['h-6', 'w-6', item.favourite ? 'fill-current' : '']" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                        </button>
                        <button class="btn btn-active btn-sm ml-2" @click.stop="handleAddPlaylist(item.id)" v-if="$route.name !== 'playlist'">
                            <svg t="1699584409505" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2741" width="64" height="64"><path d="M896 853.333333H597.333333v-85.333333h298.666667z m0-256H597.333333v85.333334h298.666667z m0-170.666666H597.333333v85.333333h298.666667z m-213.333333-42.666667a256 256 0 0 1-170.666667-65.493333V704a192 192 0 1 1-85.333333-159.573333V128h85.333333a170.666667 170.666667 0 0 0 170.666667 170.666667zM426.666667 704a106.666667 106.666667 0 1 0-106.666667 106.666667 106.666667 106.666667 0 0 0 106.666667-106.666667z" p-id="2742"></path></svg>
                        </button>
                        <button class="btn btn-active btn-sm ml-2" @click.stop="removePlayListFunc(index)" v-else>
                            <svg t="1702432196460" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2579" width="64" height="64"><path d="M683.514192 1023.999519H340.485808A154.527713 154.527713 0 0 1 186.336081 884.785983L129.317406 325.755552a39.082648 39.082648 0 0 1 77.762111-7.937715l57.053038 559.030431a76.559427 76.559427 0 0 0 76.376161 68.976791h343.005476a76.559427 76.559427 0 0 0 76.376161-68.976791l57.053038-559.030431a39.082648 39.082648 0 1 1 77.762112 7.937715l-57.053039 559.030431A154.516259 154.516259 0 0 1 683.514192 1023.999519zM888.85244 191.513109h-753.681972a39.092957 39.092957 0 0 1 0-78.17446h753.681972a39.092957 39.092957 0 0 1 0 78.17446z" p-id="2580"></path><path d="M409.199153 704.887365a39.092957 39.092957 0 0 1-39.081502-39.081503V451.991555a39.092957 39.092957 0 0 1 78.174459 0v213.814307a39.092957 39.092957 0 0 1-39.092957 39.081503zM614.789392 704.887365a39.092957 39.092957 0 0 1-39.081502-39.081503V451.991555a39.092957 39.092957 0 0 1 78.174459 0v213.814307a39.092957 39.092957 0 0 1-39.092957 39.081503zM667.844938 171.960904a39.081503 39.081503 0 0 1-39.127319-39.081503 54.773666 54.773666 0 0 0-54.716395-54.716395h-124.048264a54.773666 54.773666 0 0 0-54.716395 54.716395 39.081503 39.081503 0 1 1-78.163006 0 133.028304 133.028304 0 0 1 132.867947-132.867947H574.04704a133.028304 133.028304 0 0 1 132.867947 132.867947 39.070049 39.070049 0 0 1-39.070049 39.081503z" p-id="2581"></path></svg>
                        </button>
                        <button class="btn btn-active btn-sm ml-2" @click.stop="redirctEditDetail(item.path)">
                            <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64" height="64"><path d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path></svg>
                        </button>
                    </td>
                    <td class="!table-cell md:!hidden">...</td>
                </tr>
            </tbody>
        </table>
        <dialog ref="plist" class="modal">
            <div class="modal-box">
                <form method="dialog">
                    <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
                <h3 class="font-bold text-lg mb-4">添加至播放列表</h3>
                <div v-if="playLists.length > 0">
                    <select class="select select-bordered select-sm w-full max-w-xs" v-model="selectPlaylist">
                        <option v-for="(item, index) in playLists" :key="index" :value="item.id">{{item.name}}</option>
                    </select>
                    <button class="btn btn-sm" @click="addToPlaylistFunc">添加</button>
                </div>
                <div class="flex flex-col mb-4">
                    <a class="link mt-2 ml-1" @click="isShowCreatePlist = !isShowCreatePlist">新建播放列表</a>
                    <div v-if="isShowCreatePlist || playLists.length === 0" class="mt-1">
                        <input type="text" placeholder="请输入" class="input input-bordered input-sm w-full max-w-xs" v-model="plName" />
                        <button class="btn btn-sm" @click="createPlaylistFunc">添加</button>
                    </div>
                </div>
            </div>
        </dialog>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex'
    export default {
        components: {},
        props: {
            tracks: {type: Array, required: true},
            noAlbum: {type: Boolean, default: false},
            noArtist: {type: Boolean, default: false},
            noDuration: {type: Boolean, default: false},
            indexField: {type: String, default: 'tracknumber'}
        },
        data() {
            return {
                playLists: [],
                id: this.$route.params.id,
                isShowCreatePlist: false,
                selectPlaylist: '',
                selectTrackID: '',
                plName: ''
            }
        },
        computed: {
            ...mapGetters(['trackId']),
            isPlaying() {
                return this.$store.state.common.isPlaying
            }
        },
        created() {
        },
        methods: {
            formatDuration(value) {
                if (!isFinite(value)) {
                    return '∞'
                }
                const minutes = Math.floor(value / 60)
                const seconds = Math.floor(value % 60)
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
            },
            formatSize(value) {
                return (value / 1024 / 1024).toFixed(1) + ' MB'
            },
            play(index) {
                if (this.tracks[index].id === this.trackId) {
                    return this.$store.dispatch('playPause')
                }
                return this.$store.dispatch('playTrackList', {
                    index,
                    tracks: this.tracks
                })
            },
            dragstart(item, event) {
                if (!item.isStream) {
                    event.dataTransfer.setData('application/x-track-id', item.id)
                }
            },
            addFavouriteTrack(item) {
                if (item.favourite) {
                    this.$api.subsonic.removeFavourite(item.id, 'track').then((res) => {
                        item.favourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(item.id, 'track').then((res) => {
                        item.favourite = true
                    })
                }
            },
            handleAddPlaylist(TrackID) {
                this.selectTrackID = TrackID
                this.isShowCreatePlist = false
                this.$api.subsonic.getPlaylists().then((res) => {
                    this.playLists = res
                })
                this.$refs.plist.showModal()
            },
            addToPlaylistFunc() {
                this.$api.subsonic.addToPlaylist(this.selectPlaylist, [this.selectTrackID]).then((res) => {
                    this.$refs.plist.close()
                    this.$cwMessage('添加成功', 'success')
                })
            },
            removePlayListFunc(index) {
                this.$api.subsonic.removeFromPlaylist(this.id, index).then((res) => {
                    this.$cwMessage('删除成功', 'success')
                    this.tracks.splice(index, 1)
                })
            },
            redirctEditDetail(path) {
                if (this.$route.name !== 'home') {
                    this.$router.push({name: 'home'})
                }
                this.$store.commit('setFullPath', path)
            },
            createPlaylistFunc() {
                this.$api.subsonic.createPlaylist(this.plName, [this.selectTrackID]).then((res) => {
                    this.$cwMessage('添加成功', 'success')
                    this.$refs.plist.close()
                })
            }
        }
    }
</script>
