<template>
    <div class="overflow-y-auto h-screen-header" ref="viewhome">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/tracks/' + value }">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <TrackList :tracks="albums" :index-field="'index'" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>
<script>
    import TrackList from './TrackList'
    export default {
        components: {
            TrackList
        },
        props: {},
        data() {
            return {
                albums: [],
                loading: false,
                offset: 0,
                hasMore: true
            }
        },
        computed: {
            options() {
                return [
                    {text: '最近添加', value: 'recently-added'},
                    {text: '最近播放', value: 'recently-played'},
                    {text: '最多播放', value: 'most-played'},
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '随机播放', value: 'random'}
                ]
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                handler() {
                    this.albums = []
                    this.offset = 0
                    this.hasMore = true
                    this.loadMore()
                }
            }
        },
        mounted() {
            // 事件监听
            this.$refs.viewhome.addEventListener('scroll', this.listenBottomOut)
            window.viewhome = this.$refs.viewhome
        },
        destroyed() {
            // 离开页面取消监听
            window.viewhome.removeEventListener('scroll', this.listenBottomOut, false)
        },
        created() {
            this.loadMore()
        },
        methods: {
            loadMore() {
                this.loading = true
                return this.$api.subsonic.getTracksByGenre(this.sortValue, 50, this.offset).then(albums => {
                    this.albums.push(...albums)
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                })
            },
            listenBottomOut() {
                if (this.hasMore && !this.loading) {
                    const scrollTop = window.viewhome.scrollTop || document.body.scrollTop
                    const scrollHeight = window.viewhome.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.viewhome.clientHeight || document.body.clientHeight
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            }
        }
    }
</script>
