<template>
    <div class="h-screen-header overflow-y-auto" ref="viewhome">
        <div class="flex justify-between mb-1">
            <div class="text-2xl">: {{ artist }}</div>
        </div>

        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': section === value }">
                <div @click="handleChangeTab(value)">
                    {{ text }}
                </div>
            </a>
        </div>

        <template v-if="section === 'tracks'">
            <TrackList :tracks="tracks" :index-field="'index'" />
        </template>
        <template v-else>
            <AlbumList :items="albums" />
        </template>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>
<script>
    import AlbumList from '@/views/library/album/AlbumList.vue'
    import TrackList from '@/views/library/track/TrackList.vue'

    export default {
        components: {
            AlbumList,
            TrackList
        },
        props: {
        },
        data() {
            return {
                tracks: [],
                albums: [],
                id: this.$route.params.id,
                artist: this.$route.query.name,
                section: 'albums',
                loading: false,
                offset: 0,
                hasMore: true,
                loading2: false,
                offset2: 0,
                hasMore2: true,
                options: [
                    {text: '专辑', value: 'albums'},
                    {text: '歌曲', value: 'tracks'}
                ]
            }
        },
        mounted() {
            // 事件监听
            this.$refs.viewhome.addEventListener('scroll', this.listenBottomOut)
            window.viewhome = this.$refs.viewhome
        },
        async created() {
            await this.loadMore()
        },
        destroyed() {
            // 离开页面取消监听
            window.viewhome.removeEventListener('scroll', this.listenBottomOut, false)
        },
        methods: {
            async loadAlbums(offset) {
                this.albums = await this.$api.subsonic.getAlbumsByArtist(this.id, 50, offset)
            },
            async loadTracks(offset) {
                this.tracks = await this.$api.subsonic.getTracksByArtist2(this.id, 50, offset)
            },
            handleChangeTab(value) {
                this.section = value
                this.loadMore()
            },
            handleDelete() {
                this.$api.Task.deleteGenre({ 'id': this.id }).then((res) => {
                    if (res.result) {
                        this.$cwMessage('删除成功', 'success')
                        this.$router.push({path: '/genres/a-z/'})
                    } else {
                        this.$cwMessage('删除失败', 'error')
                    }
                })
            },
            loadMore() {
                if (this.section === 'tracks') {
                    this.loading2 = true
                    return this.$api.subsonic.getTracksByArtist2(this.id, 50, this.offset2).then(tracks => {
                        this.tracks.push(...tracks)
                        this.offset2 += tracks.length
                        this.hasMore2 = tracks.length > 0
                        this.loading2 = false
                    })
                } else {
                    this.loading = true
                    return this.$api.subsonic.getAlbumsByArtist(this.id, 50, this.offset).then(albums => {
                        this.albums.push(...albums)
                        this.offset += albums.length
                        this.hasMore = albums.length > 0
                        this.loading = false
                    })
                }
            },
            listenBottomOut() {
                let hasMore
                let loading
                if (this.section === 'albums') {
                    hasMore = this.hasMore
                    loading = this.loading
                } else {
                    hasMore = this.hasMore2
                    loading = this.loading2
                }
                if (hasMore && !loading) {
                    const scrollTop = window.viewhome.scrollTop || document.body.scrollTop
                    const scrollHeight = window.viewhome.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.viewhome.clientHeight || document.body.clientHeight
                    console.log(scrollTop + clientHeight, scrollHeight - 50)
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            }
        }
    }
</script>
