<template>
  <div>
    <div v-if="loading" class="flex justify-center">
        <span class="loading loading-infinity loading-lg"></span>
    </div>
    <slot v-else />
  </div>
</template>
<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                required: true
            }
        }
    }
</script>
