<template>
    <aside class="w-full">
        <ul class="menu bg-base-100 rounded-box">
            <li :class="[$route.name == 'playing' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/playing'}">
                    <svg t="1699584295731" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1185" width="64" height="64"><path d="M64 511.5C64 263.8 264.8 63 512.5 63S961 263.8 961 511.5V752c0 61.856-50.144 112-112 112h-56c-61.856 0-112-50.144-112-112V584c0-61.856 50.144-112 112-112h85.908C859.199 287.057 702.673 143 512.5 143S165.8 287.057 146.092 472H232c61.856 0 112 50.144 112 112v168c0 61.856-50.144 112-112 112h-56c-61.856 0-112-50.144-112-112V512v-0.25-0.25zM881 552h-88c-17.673 0-32 14.327-32 32v168c0 17.673 14.327 32 32 32h56c17.673 0 32-14.327 32-32V552z m-737 0v200c0 17.673 14.327 32 32 32h56c17.673 0 32-14.327 32-32V584c0-17.673-14.327-32-32-32h-88z"  p-id="1186"></path></svg>
                    <div class="text-base">正在播放</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'playlists' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/playlists/recently-added/'}">
                    <svg t="1699584409505" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2741" width="64" height="64"><path d="M896 853.333333H597.333333v-85.333333h298.666667z m0-256H597.333333v85.333334h298.666667z m0-170.666666H597.333333v85.333333h298.666667z m-213.333333-42.666667a256 256 0 0 1-170.666667-65.493333V704a192 192 0 1 1-85.333333-159.573333V128h85.333333a170.666667 170.666667 0 0 0 170.666667 170.666667zM426.666667 704a106.666667 106.666667 0 1 0-106.666667 106.666667 106.666667 106.666667 0 0 0 106.666667-106.666667z" p-id="2742"></path></svg>
                    <div class="text-base">播放列表</div>
                </router-link>
            </li>
            <h2 class="menu-title text-base">LIBRARY</h2>
            <li :class="[$route.name == 'albums' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/albums/recently-added/'}">
                    <svg class="inline-block w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="4475" width="32" height="32" fill="#4f14ee">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M511.701333 511.701333m-85.632 0a85.632 85.632 0 1 0 171.264 0 85.632 85.632 0 1 0-171.264 0Z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M512 85.333333C276.736 85.333333 85.333333 276.736 85.333333 512s191.402667 426.666667 426.666667 426.666667 426.666667-191.402667 426.666667-426.666667S747.264 85.333333 512 85.333333z m0 768c-188.202667 0-341.333333-153.130667-341.333333-341.333333s153.130667-341.333333 341.333333-341.333333 341.333333 153.130667 341.333333 341.333333-153.130667 341.333333-341.333333 341.333333z"
                            fill="" p-id="4477"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M512 256c-141.184 0-256 114.816-256 256h85.333333c0-94.122667 76.544-170.666667 170.666667-170.666667V256z"
                            fill="" p-id="4478"></path>
                    </svg>
                    <div class="text-base">专辑</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'artists' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/artists/most-albums'}">
                    <svg t="1695911766499" class="w-5 h-5 fill-current" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="11691">
                        <path
                            d="M535.253333 255.274667a170.666667 170.666667 0 0 0 234.154667 233.045333c-51.2-23.850667-104.618667-61.866667-138.24-95.488-34.048-34.048-72.149333-87.04-95.914667-137.557333z m65.706667-63.829334c12.928 40.277333 57.301333 107.818667 90.538667 141.056 32.682667 32.682667 99.072 76.074667 141.44 89.813334a170.666667 170.666667 0 0 0-231.936-230.869334z m-9.173333 389.290667a256.768 256.768 0 0 1-148.522667-148.522667l-259.84 373.504 34.858667 34.858667 373.504-259.84z m129.834666 13.653333L208.384 951.424l-135.808-135.808L429.653333 302.378667A256.042667 256.042667 0 0 1 938.666667 341.333333a256.042667 256.042667 0 0 1-217.045334 253.056z"
                            p-id="11692"></path>
                    </svg>                    <div class="text-base">艺术家</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'tracks' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/tracks/recently-added/'}">
                    <svg t="1711466361303" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2515" width="200" height="200"><path d="M768 436.906667v53.76a21.333333 21.333333 0 0 1-21.333333 21.333333h-42.666667a21.333333 21.333333 0 0 1-21.333333-21.333333v-11.52a115.626667 115.626667 0 0 0-64-100.693334c-7.68-4.266667-14.506667-9.386667-21.333334-14.08v384A161.28 161.28 0 0 1 426.666667 896a161.28 161.28 0 0 1-170.666667-149.333333 161.28 161.28 0 0 1 170.666667-149.333334 183.04 183.04 0 0 1 85.333333 20.906667V149.333333a21.333333 21.333333 0 0 1 21.333333-21.333333h42.666667a21.333333 21.333333 0 0 1 21.333333 21.333333v7.253334a113.92 113.92 0 0 0 61.013334 103.253333A197.12 197.12 0 0 1 768 436.906667z" p-id="2516"></path></svg>
                    <div class="text-base">歌曲</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'genres' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/genres/a-z/'}">
                    <svg t="1702346063187" class="icon w-4 h-4 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8844"><path d="M0 284.444444v455.111112a56.888889 56.888889 0 0 0 113.777778 0V284.444444a56.888889 56.888889 0 1 0-113.777778 0zM910.222222 284.444444v455.111112a56.888889 56.888889 0 0 0 113.777778 0V284.444444a56.888889 56.888889 0 0 0-113.777778 0zM455.111111 56.888889v910.222222a56.888889 56.888889 0 0 0 113.777778 0V56.888889a56.888889 56.888889 0 1 0-113.777778 0zM682.666667 170.666667v682.666666a56.888889 56.888889 0 0 0 113.777777 0V170.666667a56.888889 56.888889 0 0 0-113.777777 0zM227.555556 170.666667v682.666666a56.888889 56.888889 0 0 0 113.777777 0V170.666667a56.888889 56.888889 0 1 0-113.777777 0z" ></path></svg>
                    <div class="text-base ml-1">流派</div>
                </router-link>
            </li>
            <li :class="[$route.name == 'favourites' ? 'bg-gray-100' : '', 'rounded-xl']" @click="showSideBar">
                <router-link class="nav-link" :to="{path: '/favourites/albums/'}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                    <div class="text-base">收藏</div>
                </router-link>
            </li>
        </ul>
    </aside>
</template>
<script>

  export default {
    components: {
    },
      methods: {
          showSideBar() {
              this.$parent.showSideBar()
          }
      }
  }
</script>
