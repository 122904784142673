<template>
    <div
        style="display: flex;flex-direction: column;margin-top: 20px;flex: 1;margin-right: 20px;margin-left: 20px;">
        <div v-if="section3Loading" class="flex justify-center">
            <span class="loading loading-dots loading-md"></span>
        </div>
        <div v-else>
            <div v-if="songList.length === 0">
                <span style="margin-left: 30%;margin-top: 30%;">暂无歌曲信息</span>
            </div>
            <div v-else>
                <div class="overflow-x-auto">
                    <table class="table">
                        <!-- head -->
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>
                                    <div class="flex items-center">
                                        <div class="ml-4">封面</div>
                                        <div class="ml-9">
                                            <div>标题</div>
                                            <div>艺术家</div>
                                        </div>
                                    </div>
                                </th>
                                <th>专辑</th>
                                <th>歌词</th>
                                <th>
                                    <div>音轨号</div>
                                    <div>CD号</div>
                                </th>
                                <th>年份</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in songList" :key="index">
                                <th class="w-4">
                                    <bk-icon type="arrows-left-shape" @click="copyAll(item)"
                                        style="cursor: pointer;">
                                    </bk-icon>
                                </th>
                                <td class="max-w-1/3">
                                    <div class="flex items-center gap-3">
                                        <div class="avatar">
                                            <div class="mask mask-squircle w-16 h-16 cursor-pointer" @click="handleCopy('album_img',item.album_img)">
                                                <img :src="item.album_img" alt="" v-if="item.album_img" />
                                                <div v-else></div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="font-bold">
                                                <div @click="handleCopy('title',item.name)" :class="getClass(item.title_score)">
                                                    {{
                                                        item.name
                                                    }}
                                                </div>
                                            </div>
                                            <div class="text-sm opacity-50">
                                                <div @click="handleCopy('artist',item.artist)" :class="getClass(item.artist_score)">
                                                    {{ item.artist }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="max-w-1/4">
                                    <span class="font-bold">
                                        <div @click="handleCopy('album',item.album)" :class="getClass(item.album_score)">
                                            {{item.album }}
                                        </div>
                                    </span>
                                </td>
                                <td>
                                    <div @click="handleCopy('lyric',item)" class="music-item">
                                        <button class="btn btn-outline btn-xs" :disabled="item.resource === 'itunes'" v-if="item.resource">加载歌词{{item.resource}}</button>
                                        <button class="btn btn-outline btn-xs" :disabled="item.resource === 'itunes'" v-else>歌词</button>
                                    </div>
                                </td>
                                <th>
                                    <div v-if="item.tracknumber" class="flex">
                                        <div @click="handleCopy('tracknumber',item.tracknumber)" class="music-item">
                                            {{item.tracknumber}}
                                        </div>
                                        <div>/</div>
                                        <div @click="handleCopy('totaltracks',item.totaltracks)" class="music-item">
                                            {{item.totaltracks}}
                                        </div>
                                    </div>
                                    <div v-if="item.discnumber" class="flex">
                                        <div @click="handleCopy('discnumber',item.discnumber)" class="music-item">
                                            {{item.discnumber}}
                                        </div>
                                        <div>/</div>
                                        <div @click="handleCopy('totaldiscs',item.totaldiscs)" class="music-item">
                                            {{item.totaldiscs}}
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div @click="handleCopy('year',item.year)" class="music-item">
                                        {{ item.year }}
                                    </div>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'resource',
        props: {
            songList: {type: Array, required: true},
            section3Loading: {type: Boolean, required: true}
        },
        methods: {
            getClass(v) {
                if (v === 1) {
                    return 'text-yellow-600 music-item'
                } else if (v === 2) {
                    return 'text-neutral-600 music-item'
                } else if (v === 0) {
                    return 'text-rose-400 music-item'
                } else {
                    return 'music-item'
                }
            },
            handleCopy(k, v) {
                this.$parent.handleCopy(k, v)
            },
            copyAll(item) {
                this.$parent.copyAll(item)
            }
        }
    }
</script>

<style scoped>

</style>
