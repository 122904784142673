<template>
    <div class="flex">
        <div>
            <ul class="menu bg-base-100 rounded-box h-screen-header my-2">
                <li @click="handleSourceChange(1)">
                    <a class="tooltip tooltip-right" data-tip="标签源插件">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
                    </a>
                </li>
                <li>
                    <a class="tooltip tooltip-right active" data-tip="批量操作插件">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </a>
                </li>
            </ul>
        </div>
        <div>
            <div class="tabs my-2 mx-4">
                <a :class="{ 'tab': true, 'tab-lifted': true, 'tab-active': tabIndex === 1 }" @click="handleTabChange(1)">已安装</a>
                <a :class="{ 'tab': true, 'tab-lifted': true, 'tab-active': tabIndex === 2 }" @click="handleTabChange(2)">插件市场</a>
            </div>
            <div class="flex flex-wrap m-2" v-show="tabIndex === 1">
                <div v-for="(item,index) in actionList" :key="index">
                    <div class="card w-80 bg-base-100 shadow-xl m-2">
                        <div class="items-center text-center px-8 pb-6 h-32 pt-2">
                            <div class="flex justify-center items-center">
                                <a class="btn btn-ghost normal-case text-xl">{{item.name}}</a>
                                <div class="badge badge-secondary badge-sm text-xs">版本:{{item.version}}</div>
                            </div>
                            <p class="grow text-ellipsis overflow-hidden h-[48px]">{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="tabIndex === 2" class="flex flex-wrap m-2">
                上传自定义插件
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                size: 'small',
                data: [
                ],
                tabIndex: 1,
                pagination: {
                    current: 1,
                    count: 500,
                    limit: 15
                },
                exampleSetting1: {
                    primary: {
                        visible: false,
                        headerPosition: 'left',
                        title: '新增用户'
                    }
                },
                formData: {},
                roleList: [
                ],
                actionList: []
            }
        },
        created() {
            this.fetchActionScript()
        },
        methods: {
            handlePageChange(page) {
                this.pagination.current = page
            },
            handleTabChange(index) {
                this.tabIndex = index
            },
            handleSourceChange(index) {
                this.$router.push(
                    {'name': 'mod'}
                )
            },
            remove(row) {
                const index = this.data.indexOf(row)
                if (index !== -1) {
                    this.data.splice(index, 1)
                }
            },
            edit(row) {
                this.formData = row
                this.exampleSetting1.primary.visible = true
                this.exampleSetting1.primary.title = '修改用户'
            },
            handleClick() {
                this.exampleSetting1.primary.visible = true
            },
            fetchActionScript() {
                this.$api.Task.getActionScript({'page_size': 20}).then((res) => {
                    console.log(res.data.items)
                    if (res.result) {
                        this.actionList = res.data.items
                    }
                })
            }
        }
    }
</script>
<style lang="postcss">
.bk-table-header .custom-header-cell {
    color: inherit;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
}
</style>
