<template>
    <div>
        <div class="overflow-x-auto">
            <table class="table">
                <!-- head -->
                <thead>
                    <tr>
                        <th>封面</th>
                        <th>艺术家</th>
                        <th>专辑数</th>
                        <th>歌曲数</th>
                        <th>收藏</th>
                        <th>添加时间</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index" class="hover:bg-base-300">
                        <td class="w-16 truncate">
                            <div class="flex items-center gap-3">
                                <div class="avatar">
                                    <div class="mask mask-squircle w-12 h-12 tile-img">
                                        <img :src="item.image" v-if="item.image"
                                            alt="Avatar Tailwind CSS Component" />
                                        <img v-else src="/static/dist/img/album_null.jpg">
                                        <div class="absolute top-[30%] z-10 left-[25%] play-icon" @click.stop="playNow(item.id)">
                                            <svg class="fill-purple-800 w-6 h-6 hover:fill-purple-600" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="w-72 cursor-pointer" @click="redirctDetail(item.id)">{{item.name}}</td>
                        <td>{{item.albumCount}}</td>
                        <td>{{item.trackCount}}</td>
                        <td>{{item.favourite}}</td>
                        <td>{{item.created}}</td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</template>
<script>

    export default {
        components: {
        },
        props: {
            items: { type: Array, required: true }
        },
        created() {
            console.log(this.items)
        },
        methods: {
            async playNow(id) {
                const tracks = await this.$api.subsonic.getTracksByArtist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: tracks
                })
            },
            redirctDetail(id) {
                this.$router.push({ name: 'artist', params: { id: id } })
            }
        }
    }
</script>
<style scoped>
.play-icon {
    display: none;
    cursor: pointer;
}

.tile-img:hover .play-icon {
    display: block;
}
</style>
