<template functional>
  <div :class="[props.small ? 'tiles-sm' : 'tiles',
     props.square ? 'tiles-square' : 'tiles-rect', {'tiles-hs' : props.allowHScroll}]">
    <slot/>
  </div>
</template>
<script>
export default {
  props: {
    square: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    allowHScroll: {type: Boolean, default: false},
  }
}
</script>
<style>
.tiles {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.tiles-sm {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

@media(max-width: 442px) {
  .tiles {
    grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media(max-width: 442px) {
  .tiles-sm {
    grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

@media(max-width: 654px) {
  .tiles-hs {
    grid-template-columns: none;
    grid-auto-flow: column;
    grid-auto-columns: minmax(200px, 1fr);
    overflow-x: auto;
  }
}

.tiles-square .tile-img {
  padding-bottom: 100%;
}

.tiles-rect .tile-img {
  padding-bottom: 70%;
}
</style>
