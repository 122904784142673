<template>
    <div>
        <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 shadow-sm rounded-xl !flex">
            <a role="tab" class="tab tab-active">刮削报告</a>
        </div>
        <div>
            <div class="flex flex-col md:flex-row">
                <div class="bg-base-100 md:w-1/2 md:h-60 shadow-xl rounded-xl m-2 flex flex-col md:flex-row md:justify-between">
                    <div class="p-8">
                        <div class="mt-4 indent-4 text-gray-500">
                            <div class="flex mb-2">
                                <div class="font-medium text-md">标题:</div>
                                <div>{{detailData.name}}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">创建时间:</div>
                                <div>{{detailData.created_at}}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">执行状态:</div>
                                <div>{{detailData.status_display}}</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">运行时间:</div>
                                <div>{{detailData.run_time}}s</div>
                            </div>
                            <div class="flex mb-2">
                                <div class="font-medium text-md">结束时间:</div>
                                <div>{{detailData.complete_at}}</div>
                            </div>
                        </div>
                    </div>
                    <img src="/static/dist/img/play_music.svg" alt="Album" class="h-64 z-1" />
                </div>
                <div class="m-2 flex flex-col grow">
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl">
                        <div class="stat">
                            <div class="stat-figure text-primary">
                                <svg t="1713434023579" class="fill-secondary" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2454" width="32" height="32"><path d="M511.082 36.049c-263.069 0-476.33 212.903-476.33 475.534s213.261 475.534 476.33 475.534c263.073 0 476.33-212.903 476.33-475.534 0-262.63-213.255-475.534-476.33-475.534zM772.988 397.6l-306.63 313.276c-14.106 14.413-36.99 14.413-51.094 0l-166.087-169.696c-14.115-14.414-14.115-37.788 0-52.206 14.105-14.419 36.99-14.419 51.094 0l140.541 143.585 281.072-287.169c14.115-14.418 37.001-14.418 51.105 0 14.115 14.417 14.115 37.792 0 52.21z" p-id="2455"></path></svg>
                            </div>
                            <div class="stat-title">已完成</div>
                            <div class="stat-value text-primary cursor-pointer">{{detailData.static.complete_count}}</div>
                            <div class="stat-desc">包括成功失败和未匹配</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434236753" class="fill-primary" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3509" width="32" height="32"><path d="M873.48 933.77c-16.22 0-29.36-14.33-29.36-32v-754c0-11.05-8.22-20-18.35-20H230.75c-16.22 0-29.36-14.33-29.36-32s13.15-32 29.36-32h598.69c40.54 0 73.4 35.82 73.4 80v758c0.01 17.67-13.14 32-29.36 32z" p-id="3510"></path><path d="M713.71 224.22c8.82 0 16 7.18 16 16v640c0 8.82-7.18 16-16 16h-534c-8.82 0-16-7.18-16-16v-640c0-8.82 7.18-16 16-16h534m0-64h-534c-44.18 0-80 35.82-80 80v640c0 44.18 35.82 80 80 80h534c44.18 0 80-35.82 80-80v-640c0-44.18-35.82-80-80-80z" p-id="3511"></path><path d="M648.9 397.33H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32H648.9c17.67 0 32-14.33 32-32s-14.32-32-32-32zM564.02 549.69H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32h322.01c17.67 0 32-14.33 32-32s-14.32-32-32-32z" p-id="3512"></path></svg>
                            </div>
                            <div class="stat-title">总数</div>
                            <div class="stat-value text-secondary">{{detailData.static.total_count}}</div>
                            <div class="stat-desc">总共个数</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <div class="radial-progress text-primary" :style="`--value: ${detailData.static.complete_rate};`" role="progressbar">{{detailData.static.complete_rate}}%</div>
                            </div>
                            <div class="stat-value">{{detailData.static.complete_rate}}</div>
                            <div class="stat-title">完成进度</div>
                            <div class="stat-desc text-secondary">完成进度百分比</div>
                        </div>
                    </div>
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl mt-4">

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434348124" class="icon fill-primary" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4497" width="32" height="32"><path d="M469.333333 640l0.384 0.384L469.333333 640z m-106.282666 0l-0.384 0.384 0.384-0.384z m48.512 106.666667a87.466667 87.466667 0 0 1-61.653334-24.874667l-179.52-173.632a67.797333 67.797333 0 0 1 0-98.24c28.032-27.157333 73.493333-27.157333 101.589334 0l139.584 134.997333 319.168-308.544c28.032-27.157333 73.493333-27.157333 101.589333 0a67.925333 67.925333 0 0 1 0 98.24L472.981333 722.069333A87.530667 87.530667 0 0 1 411.562667 746.666667z"></path></svg>
                            </div>
                            <div class="stat-title">成功</div>
                            <div class="stat-value text-secondary cursor-pointer">{{detailData.static.success_count}}</div>
                            <div class="stat-desc">成功完成并匹配上的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434399481" class="icon fill-secondary" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1070" width="32" height="32"><path d="M567.168 512l189.184 188.352a40.064 40.064 0 0 1 0.768 55.68 37.76 37.76 0 0 1-54.4 0.832L512 566.912l-190.72 189.952a37.76 37.76 0 0 1-54.4-0.768 40.064 40.064 0 0 1 0.768-55.68L456.832 512 267.648 323.648a40.064 40.064 0 0 1-0.768-55.68 37.76 37.76 0 0 1 54.4-0.832L512 457.088l190.72-189.952a37.76 37.76 0 0 1 54.4 0.768 40.064 40.064 0 0 1-0.768 55.68L567.168 512z"></path></svg>
                            </div>
                            <div class="stat-title">失败</div>
                            <div class="stat-value text-primary cursor-pointer">{{detailData.static.fail_count}}</div>
                            <div class="stat-desc">文件异常或网络异常的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713494194037" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3344" width="32" height="32"><path d="M765.610667 518.485333H298.666667V469.333333h466.944z" fill="#000000" fill-opacity=".85" p-id="3345"></path></svg>
                            </div>
                            <div class="stat-title">未匹配</div>
                            <div class="stat-value">{{detailData.static.miss_count}}</div>
                            <div class="stat-desc">元数据匹配不正确的</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div v-if="detailData.name === '重复检查'">
            <duplicate-log-detail :detail-data="detailData"></duplicate-log-detail>
        </div>
        <div class="pt-2 mx-8" v-else>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.running.length > 0">
                <input type="checkbox" v-model="isRunning" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>进行中</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.running_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.running" :key="'running' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_tag.length > 0">
                <input type="checkbox" v-model="isMissTag" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>未匹配到标签</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.miss_tag_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.miss_tag" :key="'miss_tag' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.skip.length > 0">
                <input type="checkbox" v-model="isSkip" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>跳过匹配</div>
                        <div class="badge badge-neutral gap-2 ml-2">
                            {{detailData.skip_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.skip" :key="'skip' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_artist.length > 0">
                <input type="checkbox" v-model="isMissArtist" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失艺术家</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.miss_artist_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.miss_artist" :key="'miss_artist' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_album.length > 0">
                <input type="checkbox" v-model="isMissAlbum" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失专辑</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.miss_album_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.miss_album" :key="'miss_album' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_artist_album.length > 0">
                <input type="checkbox" v-model="isMissAA" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失艺术家和专辑</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.miss_artist_album_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.miss_artist_album" :key="'miss_artist_album' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.fail.length > 0">
                <input type="checkbox" v-model="isFail" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>异常失败</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{detailData.fail_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.fail" :key="'fail' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.mode_success.length > 0">
                <input type="checkbox" v-model="isModeSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>{{detailData.mode_name}}</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.mode_success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                    <th>原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.mode_success" :key="'mode' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.success.length > 0">
                <input type="checkbox" v-model="isSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>完全匹配</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>标题</th>
                                    <th>艺术家</th>
                                    <th>专辑</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.success" :key="'succ' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.title}}</td>
                                    <td>{{item.artist}}</td>
                                    <td>{{item.album}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import duplicateLogDetail from "@/views/log/duplicateLogDetail";

    export default {
        name: 'log-detail',
        components: {
            duplicateLogDetail,
        },
        data() {
            return {
                id: this.$route.params.id,
                detailData: {
                    'success': [],
                    'fail': [],
                    'mode_success': [],
                    'miss_artist_album': [],
                    'miss_artist': [],
                    'miss_album': [],
                    'miss_tag': [],
                    'static': {
                        'complete_count': 0
                    }
                },
                isOpen: true,
                timer: null
            }
        },
        computed: {
            isSuccess() {
                return this.detailData.success.length > 0
            },
            isFail() {
                return this.detailData.fail.length > 0
            },
            isModeSuccess() {
                return this.detailData.mode_success.length > 0
            },
            isMissAA() {
                return this.detailData.miss_artist_album.length > 0
            },
            isMissArtist() {
                return this.detailData.miss_artist.length > 0
            },
            isMissAlbum() {
                return this.detailData.miss_album.length > 0
            },
            isMissTag() {
                return this.detailData.miss_tag.length > 0
            },
            isRunning() {
                return this.detailData.running.length > 0
            },
            isSkip() {
                return this.detailData.skip.length > 0
            }
        },
        mounted() {
            this.handlePolling()
        },
        beforeDestroy() {
            this.setTimerNull() // 组件销毁前清空定时器
        },
        methods: {
            fetchDetails() {
                this.$api.Task.getAuditLogDetail({id: this.id}).then((res) => {
                    if (res.result) {
                        console.log(res.data)
                        this.detailData = res.data
                    }
                })
            },
            // 轮询开启
            handlePolling() {
                // 查询接口
                this.fetchDetails()
                this.timer = setInterval(() => {
                    if (this.detailData.status === 'success') {
                        this.setTimerNull()
                    } else {
                        this.fetchDetails()
                    }
                }, 3000)
            },
            setTimerNull() {
                clearInterval(this.timer)
                this.timer = null
            }
        }
    }
</script>

<style scoped>

</style>
