<template>
    <div>
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/favourites/' + value }" class="w-full">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <ArtistList v-if="sortValue === 'artists'" :items="details.artists" />
        <TrackList v-else-if="sortValue === 'tracks'" :tracks="details.tracks" />
        <AlbumList v-else :items="details.albums" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>
<script>
    import AlbumList from '@/views/library/album/AlbumList.vue'
    import ArtistList from '@/views/library/artist/ArtistList.vue'
    import TrackList from '@/views/library/track/TrackList.vue'

    export default {
        components: {
            AlbumList,
            ArtistList,
            TrackList
        },
        props: {
        },
        data() {
            return {
                options: [
                    {text: '专辑', value: 'albums'},
                    {text: '艺术家', value: 'artists'},
                    {text: '歌曲', value: 'tracks'}
                ],
                loading: false,
                details: {'albums': [], 'artists': [], 'tracks': []}
            }
        },
        computed: {
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        async created() {
            this.details = await this.$api.subsonic.getFavourites()
            console.log(this.details)
        },
        methods: {
        }
    }
</script>
