<template>
    <div class="overflow-y-auto h-screen-header">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/genres/' + value }">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <Tiles>
            <Tile v-for="item in sortedItems" :key="item.id"
                :to="{ name: 'genre', params: { id: item.id } }"
                :title="item.name" :image="item.image">
                <template #text>
                    <div class="text-sm">
                        <strong>{{ item.albumCount }}</strong> 专辑 •
                        <strong>{{ item.trackCount }}</strong> 歌曲
                    </div>
                </template>
                <template #play>
                    <div class="absolute top-[43%] z-10 left-[39%] play-icon" @click="playNow(item.id)">
                        <svg class="fill-primary w-12 h-12 hover:fill-accent" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                    </div>
                </template>
            </Tile>
        </Tiles>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>
<script>
    import Tiles from '@/components/library/Tiles'
    import Tile from '@/components/library/Tile'
    export default {
        components: {
            Tiles, Tile
        },
        props: {
            sort: {type: String, default: null}
        },
        data() {
            return {
                loading: true,
                items: [],
                options: [
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '最多专辑', value: 'most-albums'},
                    {text: '最多歌曲', value: 'most-tracks'}
                ]
            }
        },
        computed: {
            sortedItems() {
                return this.items
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            sortValue: {
                async handler() {
                    this.items = await this.$api.subsonic.getGenres(this.sortValue)
                }
            }
        },
        async created() {
            this.items = await this.$api.subsonic.getGenres(this.sortValue)
            this.loading = false
        },
        methods: {
            async playNow(id) {
                const genre = await this.$api.subsonic.getTracksByGenre(id, 100)
                return this.$store.dispatch('playTrackList', {
                    tracks: genre
                })
            }
        }
    }
</script>
